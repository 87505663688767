import React, { useRef, useState } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import {
  reset,
  themes,
  Window,
  WindowHeader,
  WindowContent,
  Button,
  Progress,
} from 'react95';

import downloadAnimation from './download_animation_95.gif';
import songFinalCountdown from './final_countdown.mp3';

const ResetStyles = createGlobalStyle`
  ${reset}
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// For use in highlighting shortcut letters on buttons
const Underlined = styled.span`
  text-decoration: underline;
`;

// An alternative to using tables to display "download metadata"
const DownloadMeta = styled.p`
  padding-top: 2px;
  span {
    display: inline-block;
    width: 150px;
  }
`;

function App() {
  const playerRef = useRef();
  const [played, setPlayed] = useState(false);

  const filename = 'babymiller.exe';
  const website = 'millerti.me';

  const dueDate = new Date(2020, 8, 20);
  const now = new Date();
  const diff = dueDate - now;
  // Assume 40 weeks @ 7 days/week
  const pregnancyDuration = 40 * 7;

  // Prevent daysLeft from being less than 0
  const daysLeft = Math.max(
    Math.floor(diff / 1000 / 60 / 60 / 24),
    0,
  );
  const downloadProgress = Math.ceil((pregnancyDuration - daysLeft) / pregnancyDuration * 100);

  function onClick () {
    if (playerRef.current.paused) {
      console.log('Easter egg, activate! 🎵')
      playerRef.current.play();
      setPlayed(true);
    }
  }

  return (
    <>
      <audio src={songFinalCountdown} ref={playerRef} loop />
      <ResetStyles />
      <ThemeProvider theme={themes.default}>
        <Container>
          <Window style={{ width: 500 }}>
            <WindowHeader
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontWeight: 'unset',
              }}
            >
              <span role="img" aria-label="World">
                {`${downloadProgress}% of ${filename} Completed`}
              </span>
              <Button
                size={'sm'}
                square
                disabled={played}
                style={{ marginRight: '-6px', marginTop: '1px' }}
                onClick={onClick}
              >
                <span style={{ fontWeight: 'bold', transform: 'translateY(-1px)' }}>x</span>
              </Button>
            </WindowHeader>
            <WindowContent style={{ paddingTop: 0 }}>
              <img src={downloadAnimation} alt="download animation"/>
              <p style={{ lineHeight: 1.5 }}>
                Downloading:
                <br/>
                {`${filename} from ${website}`}
              </p>
              <Progress percent={downloadProgress} width={500-42} />
              <DownloadMeta>
                <span>Estimated time left:</span>
                {`${daysLeft} days (approx. ${dueDate.toLocaleDateString()})`}
              </DownloadMeta>
              <DownloadMeta>
                <span>Download to:</span>
                Long Beach, CA
              </DownloadMeta>
              <DownloadMeta>
                <span>Transfer Rate:</span>
                1 day/day
              </DownloadMeta>
              <br/>
              <br/>
              <div style={{ textAlign: 'right' }}>
                <Button disabled style={{ marginRight: 8 }}>
                  <Underlined>O</Underlined>pen
                </Button>
                <Button disabled style={{ marginRight: 8 }}>
                  Open&nbsp;<Underlined>F</Underlined>older
                </Button>
                <Button disabled={played} onClick={onClick}>
                  Cancel
                </Button>
              </div>
            </WindowContent>
          </Window>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default App;
